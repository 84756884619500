.nav-conatiner {

    a, a:hover {
        color: #fff;
    }

    .first-nav {
        height: 25px;
        line-height: 42px;
       // background-color: $mainColor;
      //  color: #fff;

        z-index: 6;
        position: relative;

        .colI {
            text-align: start;


            a {
                position: relative;

                @media #{$minMed} {
                    padding: 0 15px;
                }

                &.FAQ {
                    @media #{$maxMed} {
                        padding-inline-start: 0;
                        padding-inline-end: 15px;
                    }
                }

                &:first-of-type {
                    padding-inline-start: 0;
                }

                &.FAQ::after {
                    @media #{$minMed} {
                        content: "";
                        height: 100%;
                        width: 1px;
                        background-color: #ffffffcc;
                        top: 2px;
                        margin-inline-start: 15px;
                        position: absolute;
                    }
                    
                }

                

            }
            a.FAQ, i {
                @media #{$maxSmall} {
                    font-size: 13px;
                }
    
                @media #{$maxMobile} {
                    font-size: 11px;
                }
            }
            
        }

        .colII {

            text-align: end;


            @media #{$maxSmall} {
                font-size: 12px;
            }

            @media #{$maxMobile} {
                font-size: 10px;
            }

            i {
                position: relative;
                margin-inline-start: -25px;

                @media #{$maxMobile} {
                    position: absolute;
                    top: 16px;
                    margin-inline-start: -12px;
                }
            }

            .p-dropdown {
                @media #{$minSmall} {
                    padding-inline-end: 35px;
                }
                z-index: 2;
            }
            @media #{$maxMobile} {
                i {
                     display: none;
                }
             }
        }
 
        .colII .p-dropdown {
            background-color: transparent;
            outline: 0;
            border: 0;
            box-shadow: none;
        }
        .p-dropdown .p-dropdown-label.p-placeholder {
           // color: #ffffff;
         //   padding: 0;
            font-weight: 500;
            font-size: $sm-size;
        }
        .p-dropdown-trigger {
            display: none;
        }

    } 

    .second-nav {
        // overflow: hidden;
        max-height: 88px;
        line-height: 88px;
        background-color: #fff;

        @media #{$maxSmall} {
            max-height: 80px;
            line-height: 80px;
        }

        @media #{$maxMobile} {
            max-height: 70px;
            line-height: 70px;
        }

        a {
            color: $textColor !important;
        }

        
        .drop-listII button * {
            @media #{$maxSmall} {
                font-size: 14px;
            }
            @media #{$maxMobile} {
                font-size: 12px;
            }
        }

        .clearAll {
            padding: 9px !important;
            font-size: 12px;
            border-bottom: 1px solid #ccc;
        }
        
        .reset-button i {
            font-size: 22px;
            color: $mainColor;
            margin-inline-end: 10px;
            margin-top: -60px;
            @media #{$maxSmall} {
                font-size: 20px;
            }
            @media #{$maxMobile} {
                font-size: 15px;
            }
        }

        .colI {
            img {
                @media #{$maxMed} {
                    width: 120px
                }
                @media #{$maxSmall} {
                    width: 100px
                }
                @media #{$maxMobile} {
                    width: 60px
                }
            }
        }
        
        .colIII {
            text-align: end !important;
            justify-content: unset;
            align-items: unset;
            @media #{$maxMed} {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
            a {
                text-align: center
            }
        }
        
        .colII, .colIII {
            text-align: center;
            text-transform: capitalize;

            a {
                font-size: 13px;
                padding: 0 15px;
                font-weight: 600;
                display: inline-block;
                @media #{$maxLarge} {
                    padding: 0 13px;
                    font-size: 12px;
                }
            }
        }

        .colII {

            @media #{$maxSmall} {
                font-size: 12px;
            }

            @media #{$maxMobile} {
                font-size: 10px;
            }
            
            a {
                &.active {
                    color: $mainColor !important;
                }
            }

            &.small-nav {
                padding-inline-end: 20px;
                position: fixed;
                height: 100vh;
                background: $mainColor;
                top: 0;
                left: -80%;
                width: 80%;
                z-index: 2001;
                display: flex;
                padding-top: 80px;
                padding-inline-start: 30px;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                transition: all .4s ease-in-out;

                &.active {
                    left: 0;
                }

                a {font-size: 20px; color: #fff !important;font-family: 'MontserratRegular';}

                @media #{$maxSmall} {
                    padding-top: 50px;
                    a {font-size: 18px}
                }
    
                @media #{$maxMobile} {
                    padding-top: 50px;
                    a {font-size: 15px}
                }

                button {
                    width: 100%;
                    text-align: end;
                    color: #fff;
                    font-size: 20px;
                    margin-top: 15px;
                    i {
                        color: #fff;
                    }
                }

            }
        }

        .colIII a:last-of-type {
            width: 120px;
            height: 45px;
            line-height: 42px !important;
            padding: 0 20px;
            border-radius: 26px;
            box-shadow: 0 4px 10px 0 #ff671f66;
            border: solid 1px #ff671f;
            background-color: #ff671f;
            color: #fff !important;
            font-weight: 300;

            &:focus {
                box-shadow: 0 4px 10px 0 #ff671f66 !important;
            }

            @media #{$maxLarge} {
                width: max-content;
                height: fit-content;
            }
        }

        .dropdown-menu {
            top: 95%;
            left: -90px;
            // min-width: 15rem;

            @media #{$maxMed} {
                position: absolute !important;
            }
            .dropdown-item {
                font-size: 16px;
                @media #{$maxSmall} {
                    font-size: 13px;
                }
                a {
                    color: $textColor;
                    width: 100%;
                    display: inline-block;
                    
                    &:hover {
                        color: $mainColor
                    }
                }
                &:active {
                    background-color: #fff;
                    color: $textColor;
                }
                &::before {margin-right: 1px;}
                
                &#dashboard:before {
                    content: url("../../../assets/Icons/dash.png");
                    position: relative; 
                    z-index: 100000;
                    left: -6px;
                    top: 3px;
                }
    
                &#settings:before {
                    content:url("../../../assets/Icons/cog.png");
                    position: relative; 
                    z-index: 100000;
                    left: -6px;
                    top: 3px;
                }
    
                &#logout:before {
                    content: url("../../../assets/Icons/invalid-name.png");
                    position: relative; 
                    z-index: 100000;
                    left: -6px;
                    top: 1px;
                }
            }

            a {
                line-height: 20px !important;
            }


            &.M-DL {
                text-transform: capitalize;
                width: 230px;
                p, span, a, button {
                    font-size: 12px;
                    color: #7e7e7e !important;
                    margin-bottom: 8px;
                }
            }
            
            }

        .dropdown-toggle-split::after {
            display: none !important;
        }
        
        .sp-end-col {
            display: flex;
            flex-wrap: wrap;
            justify-content: end;
            padding-inline-end: 0;
            .dropdown-toggle {
                & > span {
                    
                    @media #{$maxLarge} {
                        display: none;
                    }
                    @media #{$minXLarge} {
                        font-size: 12px;
                    }
                    @media (min-width: 1300px) {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

.p-dropdown-panel {
    
    @media #{$maxSmall} {
        font-size: 13px;
    }
    
    @media #{$maxMobile} {
        font-size: 11px;
    }

    border: 1px solid #686868 !important;
    transition: all .3s ease-in-out;

    &:hover {
        border: 1px solid $mainColor !important;
        transition: all .3s ease-in-out;
    }

    .p-dropdown-item {

        padding: 7px 10px;
        background-color: #fff;
        transition: all .3s ease-in-out;
        text-align: center;

        &:first-of-type {
            border-bottom: 1px solid #c6c6c6;
        }
        &:hover {
            background-color: $mainColor;
            color: #fff;
        }
    }
}

.social-links {
    display: inline-block;
    a {
        padding: 0 10px;
    }
}