h1,
h2,
h3 {
  margin: 0;
  font-weight: bold;
}

body {
  text-align: start;
  margin: 0;
  font-family: MontserratMedium;
}

.mainColor {
  color: $mainColor !important;
}

.bgMainColor {
  background: $mainColor;
  color: #FFF;
}

.bgSideColor {
  background: $sideColor;
  color: #FFF;
}

.page-title {
  font-size: 48px;
  color: #393939;
}

.section-title {
  font-size: clamp(20px, 3vw, 30px);
  color: #000;
  margin-left: 5%;
}

.sub-title {
  font-size: clamp(18px, 3vw, 24px);
}

.padge-pill.service-category-small {
  font-size: 15px;
  color: #0033a0;
}

.description {
  font-size: clamp(18px, 2.5vw, 20px);
}

.text-primary {
  color: blue !important;
  white-space: pre-wrap;
}

.video-placeholder {
  position: relative;

  .overlay {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: 0.1;
  }
}

.gray-bg {
  background: rgba(0, 51, 160, 0.05);
}

.main-button {
  border-radius: 0.2rem;
  color: white !important;
  background-color: $sideColor;
  width: 135px;
  min-height: 35px;
  outline: 0;
  border: $sideColor;
  font-family: MontserratRegular;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin: 0px 10px;
  display: block;
  line-height: 35px;
  padding: 8px;

  @media #{$maxMed} {
    font-size: 14px;
    padding: 10px;
  }

  @media #{$maxSmall} {
    font-size: 12px;
    padding: 8px;
  }

  @media #{$maxMobile} {
    font-size: 10px;
    padding: 6px;
  }

  i {
    padding: 5px;
  }


  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:active {
    background-color: $sideColor !important;
    border-color: $sideColor !important;
  }

}

.main-buttonII {
  border-radius: 0.2rem;
  color: white !important;
  background-color: $mainColor;
  min-height: 25px;
  outline: 0;
  border: $mainColor;
  font-family: MontserratRegular;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  line-height: 25px;
  padding: 8px;
  font-size: $sm-size;

  @media #{$maxMobile} {
    padding: 6px;
  }
}

.submit-button {
  border-radius: 0.2rem;
  color: white !important;
  background-color: $mainColor;
  min-height: 25px;
  outline: 0;
  border: $mainColor;
  font-family: MontserratRegular;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  line-height: 25px;
  padding: 10px 45px;
  text-transform: capitalize;
  margin-top: 30px;

  @media #{$maxMed} {
    font-size: 14px;
  }

  @media #{$maxSmall} {
    font-size: 12px;
  }

  @media #{$maxMobile} {
    font-size: 10px;
    padding: 6px;
  }

  &.custom {
    padding: 0;

    a {
      width: 150px;
      height: 45px;
      line-height: 45px !important;
      color: #fff;
    }
  }
}


.reset_button {
  border: 0;
  background: none;
  margin-bottom: 12px;
  line-height: 1 !important;
}

.reset_a {
  color: $text3Color !important;
}

.text-end {
  text-align: end;
  white-space: pre-wrap;
}

.borderd-button {
  border-radius: 0.2rem;
  border: solid 1px #fff;
  width: 135px;
  min-height: 35px;
  font-size: 18px;
  text-align: center;
  margin: 10px;
}

.p-50 {
  padding: 50px 0px;
}

button a {
  display: block;
  width: 100%;
  height: 100%;
  line-height: 36px;
}

a:focus {
  outline: none !important;
  box-shadow: 0 0 0 0 !important;
}

button:focus {
  outline: 0;
}

form {
  font-size: 13px !important;
}

a {
  text-decoration: none !important;
}

.p-message .p-message-wrapper {
  font-size: 16px;

  @media #{$maxSmall} {
    font-size: 12px;
  }
}

.error-msg {
  padding: 10px !important;
  margin: 10px 0px !important;

  &,
  & span {
    font-size: 21px !important;

    @media #{$maxMed} {
      font-size: 17px !important;
    }

    @media #{$maxSmall} {
      font-size: 15px !important;
    }

    @media #{$maxMobile} {
      font-size: 12px !important;
    }
  }
}

.custom-error-msg {
  display: block;
  font-size: 12px;
  background: #ffcdd2;
  border: solid #e60017;
  border-width: 0 0 0 6px;
  color: #73000c;
  padding: 10px;
  margin: 5px 0 5px;
  text-transform: none;
  // text-transform: none;
}

input {
  outline: 0;
}

.p-toast {
  position: fixed;

  @media #{$maxMobile} {
    width: 80% !important;
  }

  .p-toast-message .p-toast-message-content {
    font-size: 14px;
  }
}

.spinner-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff8c;
  top: 0;
  left: 0;
  z-index: 1600;

  i {
    transform: translateY(-50%);
    top: 50%;
    position: fixed;
    left: 48%;

    @media #{$maxSmall} {
      left: 47%;
    }

    @media #{$maxMobile} {
      left: 46%;
    }
  }
}

.p-paginator.p-component {
  padding: 10px;
  bottom: 0;
  width: 100%;
  margin: 10px 0px 0px;

  // box-shadow: 0px 0px 10px #eee;
  .p-paginator-pages .p-paginator-page.p-highlight {
    background: $sideColor;
    border-color: $sideColor;
    color: #fff;
  }

  @media #{$maxMed} {
    width: 100%;
    left: 0;
  }
}

.flip-img {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.disabled-overlay {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: #fff;
    opacity: 0.6;
  }
}

.save-button {
  width: 163px;
  height: 55px;
  font-size: 17px !important;
  background-color: $sideColor !important;
  border-color: $sideColor !important;
}

.overlay {
  background-color: white;
  width: 100%;

  &::before {
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 11;
    background-color: rgba(255, 255, 255, 0.4);
  }
}

button {
  outline: 0;
  box-shadow: none !important;
}

.main-color {
  color: $mainColor !important;
}

.side-color {
  color: $sideColor !important;
}

.no-cursor {
  cursor: unset !important;
}

#sp-account-details {
  .p-tabview:not(.local-gov)>.p-tabview-nav-container>.p-tabview-nav-content>.p-tabview-nav {
    justify-content: center;
    border: unset;


    li a {
      border: 1px solid #0033a0;
      border-width: 1px 0px 1px 0px;
      border-style: solid;
      margin-bottom: 1px;
      border-radius: 0px;
      font-size: $sm-size;
      padding: .75em;
    }

    li:first-of-type a {
      border-left: 1px solid #0033a0;
      border-radius: 5px 0px 0px 5px;
    }



    li:nth-of-type(4) a {
      border-right: 1px solid #0033a0;
      border-radius: 0px 5px 5px 0px;
    }

    li.p-highlight a {
      background: #0033a0;
      color: white;
    }

  }

  .p-tabview-nav-container {
    margin-top: 30px;
    margin-bottom: 20px;
  }

}


.global-head {
  font-size: 35px;
  color: #4a4a4a;
  font-family: AvenirMedium;
  font-weight: 600;

  @media #{$minLarge} {
    display: inline-block;
  }

  @media #{$maxMed} {
    font-size: 25px !important;
  }

  @media #{$maxMobile} {
    font-size: 20px !important;
  }
}

.line-through {
  text-decoration: line-through;
}

input {
  &:-webkit-autofill {
    -webkit-text-fill-color: $textColor;

    &:focus {
      -webkit-text-fill-color: $textColor;
    }

    &:hover {
      -webkit-text-fill-color: $textColor;
    }
  }
}

.responsive-button {
  @media #{$maxMed} {
    font-size: 14px !important;
    width: 140px !important;
    height: 50px !important;
  }

  @media #{$maxSmall} {
    font-size: 12px !important;
    width: 120px !important;
    height: 45px !important;
  }

  @media #{$maxMobile} {
    width: 110px !important;
    height: 40px !important;
    padding: 12px;
  }
}

.ql-editor {
  text-align: initial !important;
}

.form-control {
  @media #{$maxMed} {
    padding: 18px !important;
    margin: 18px 0 !important;
    font-size: 20px !important;

    &::placeholder {
      font-size: 20px !important;
    }
  }

  @media #{$maxSmall} {
    padding: 15px !important;
    margin: 15px 0 !important;
    font-size: 18px !important;

    &::placeholder {
      font-size: 18px !important;
    }
  }

  @media #{$maxMobile} {
    padding: 10px 15px !important;
    margin: 8px 0 !important;
    font-size: 13px !important;

    &::placeholder {
      font-size: 13px !important;
    }
  }
}

.p-paginator button.p-paginator-element {
  @media #{$maxMobile} {
    min-width: 25px !important;
    font-size: 10px !important;

    span {
      font-size: 10px !important;
    }
  }
}

.p-toast {
  z-index: 17;
}

.disabled-style {
  cursor: no-drop;
}

.p-toast .p-toast-message .p-toast-message-content {
  @media #{$maxSmall} {
    font-size: 12px;
  }
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  @media #{$maxSmall} {
    font-size: 1.5rem !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.visually-hidden,
.carousel-control-prev,
.carousel-control-next,
.carousel-indicators {
  display: none;
}

.container-fluid {
  @media screen and (min-width: 1920px) {
    //For Projectors or Higher Resolution Screens (Full HD)
    width: 1899px;
    margin-left: auto;
    margin-right: auto;
  }
}

a {
  line-height: inherit !important;
  // &, &:hover {
  //   color: #fff;
  // }
}

#root {
  direction: inherit;
  // overflow-x: hidden;
}

.reset-button {
  line-height: 0 !important;
  border: 0;
  background: 0;
  margin-top: -40px;
}

.responsive-head {

  @media #{$maxLarge} {
    font-size: 35px !important;
  }

  @media #{$maxMed} {
    font-size: 30px !important;
  }

  @media #{$maxSmall} {
    font-size: 25px !important;
  }

  @media #{$maxMobile} {
    font-size: 20px !important;
  }

  @media (max-width: 450px) {
    font-size: 16px !important;
  }

}

.min-H {
  min-height: calc(100vh - (130px + 404.6px));
}

.max-H {
  max-height: 70px;
  overflow: hidden;
}

.register,
.addition-page {

  .p-fileupload-buttonbar,
  .p-fileupload-row {
    display: none;
  }

  .p-progressbar.p-component.p-progressbar-determinate {
    height: 0 !important;
  }

  .id-photo .group-input {
    cursor: pointer;
  }

  .p-fileupload {
    @media #{$maxMobile} {
      display: none !important;
    }
  }

  .p-fileupload-content {
    position: absolute;
    width: 50%;
    right: 16px;
    z-index: 1;
    padding: 19px !important;
    background: transparent !important;
    border: 0 !important;
  }
}

.reset-bold {
  font-weight: 200;
}

.child-capital * {
  text-transform: none;
}

.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0.5rem !important;
}

.text-start {
  text-align: start;
  white-space: pre-wrap;
}

.text-end {
  text-align: end;
  white-space: pre-wrap;
}

.pl-2 {
  padding-inline-start: 10px;
}

.pr-2 {
  padding-inline-end: 10px;
}

.pl-lg-2 {
  @media #{$minLarge} {
    padding-inline-start: 10px;
  }
}

.pr-lg-2 {
  @media #{$minLarge} {
    padding-inline-end: 10px;
  }
}

.title-color {
  color: #393939;
}

textarea {
  resize: none;
}

* {
  text-transform: none;

  &::placeholder,
  select {
    text-transform: none !important;
  }

}

input {
  text-transform: none;
}

.ril-image-current.ril__image {
  display: flex;

  iframe {
    margin: auto;
  }
}

.serv_img {
  height: 260px;
  // float: left;
}

#CheckID {
  position: relative;
  top: 2px;
}

.noMarginParent {
  margin: 0 -10px;
  margin-left: 2%;
}

.details_date span {
  font-size: 12px;
  color: $mainColor;
  display: inline-block;
}

.reset-LH {

  &,
  & * {
    line-height: normal;
  }
}

.card-text {
  color: $text3Color ;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.texts {
  min-height: 100px;
  margin-bottom: 40px;
  white-space: pre-wrap;

  @media #{$minLarge} {
    min-height: 350px;
  }
}

.white {
  color: #fff;
}

.p-fileupload {
  display: none;
}

.img-h {
  height: 185px;
}

.custom-in {

  input,
  .upload-doc-btn {
    width: 100% !important;
    width: 100% !important;
    font-size: 12px;
    height: auto !important;
    padding: 10px !important;
    border-radius: 4px !important;
    border: solid 1px rgba(166, 166, 166, 0.2) !important;
  }
}

.admin-SB {
  background-color: #0033a0;
  color: #fff;
  position: sticky;
  top: 0;
  height: 100vh;

  img {
    width: 170px;
    margin: auto;
    margin-top: 30px;
  }

  .SB-cont {
    height: -webkit-fill-available;
    position: relative;

    ul {

      li {

        &.logout {
          position: absolute;
          bottom: 0px;
        }

        a {
          width: 100% !important;
          display: inline-block;
          padding: 10px;
          margin-inline-start: -31px;

          &.active {
            background: linear-gradient(to left, #0033a0, #fff 500%, #a09eff 40%);
            border-inline-start: 2px solid #c6c5fa;
          }
        }

        a {
          display: inline-block;
          color: #fff;
          font-size: 16px;
        }
      }
    }
  }

}

.media img {
  max-width: 100px;
}

.small-text {
  font-size: 12px;
  color: $text3Color;
}

.admin-cont {

  .card-title {
    font-size: $lg-size !important;
    margin-top: 1%;
  }

  .p-paginator.p-component {
    position: absolute;
    left: 0;
  }

  .cards_list {
    margin-bottom: 65px;
  }

  .table-title {
    font-size: 12px;
  }

  .statistics {
    .p-paginator.p-component {
      position: unset;
    }

    .global-box {
      border-radius: 10px;
      border: solid 1px #979797;
      padding: 10px 20px;
      color: $textColor;

      p {
        margin-top: 5px;
        margin-bottom: 0;
      }
    }

    .p-datatable .p-datatable-thead>tr>th,
    .p-datatable .p-datatable-tbody>tr>td {
      text-align: start;
    }
  }
}

.smallPic {
  width: 30px;
  height: 30px;
}

.p-dropdown-clear-icon {
  left: 2.357rem !important;
  right: unset !important;
}

.modal-style {
  min-height: 150px;
  padding: 20px;
}

.noti-list {

  #dropdown-button-drop-up {
    width: 230px;

    .dropdown-item {
      font-size: 12px !important;
      white-space: pre-wrap;
    }
  }

  .seeMore {
    line-height: normal;
    font-size: 15px;
    padding: 10px 0;
    color: $mainColor !important;
  }

  .dropdown-item {

    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }

    padding: 0;

    a {
      padding: 10px;
    }
  }

  .notification {
    background-color: $mainColor;
    border-color: $mainColor;

    &:hover {
      background-color: $mainColor;
    }

    .bg-secondary {
      background-color: #ffffff !important;
      color: $mainColor;
    }

    i {
      margin-inline-end: 10px;
      margin-top: 5px;
    }
  }
}

.disabled-overlay {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: #fff;
    opacity: 0.6;
  }
}

.cookiesContent {
  flex: unset !important;
}

#rcc-confirm-button {
  background-color: $sideColor !important;
  color: #fff !important;
}

.beneCode span {
  text-transform: none !important;
}

.videoDi {
  width: 50vw;
  height: 50vh;
}

.filter-form {

  @media #{$maxMed} {
    padding-inline-end: 20px;
    position: fixed;
    height: 100vh;
    background: #f2f5fa;
    top: 0;
    left: -80%;
    width: 80%;
    z-index: 2000;
    display: flex;
    padding-top: 80px;
    padding-inline-start: 30px;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    transition: all .4s ease-in-out;

    * {
      font-size: $sm-size !important;
    }

    button.toggle-btn {
      width: 100%;
      text-align: end;
      color: #fff;
      margin-bottom: 10px;

      i {
        color: #666;
        font-size: 20px !important;
      }
    }

    &.active {
      left: 0;
    }

  }


  .form-group {
    padding-right: 5px !important;
  }
}

.main-title-res {
  font-size: clamp(22px, 3.5vw, 36px);
}

.no-cont {
  font-size: $lg-size;
}

.filters {

  @media #{$maxMed} {
    text-align: end;
    background: none;
    margin-bottom: -90px;
    margin-top: 35px;
  }

  .open-btn {

    margin-bottom: -50px;

    i {
      color: #0033a0;
    }

  }

}

.final-step {
  .PIS {
    @media #{$minMed} {
      padding-inline-start: 20px !important;
    }
  }

  .PIE {
    @media #{$minMed} {
      padding-inline-end: 20px !important;
    }
  }
}

.add-more {
  font-size: $md-size;

  @media #{$maxSmall} {
    margin: auto;
    display: block;
  }
}

.mig-acc {
  font-size: $xxl-size;
  margin-bottom: 10px;
}

.w3-button {
  background-color: #f2f5fa;
  color: #393939;
  margin-left: 7%;
  margin-bottom: 1%;
  font-size: 13px;
  border-radius: 19px;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  -webkit-transition-duration: 0.4s;
  /* Safari */
  transition-duration: 0.4s;
}

.w3-button:hover {
  background-color: #0056b3;
}

.service .service-item {
  position: relative;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 0 45px rgba(0, 0, 0, .08);
}

.service-content::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  //background: #0062cc;
  //background: #ff671f;
  //background: #66b0ff;
  background: #003e80;
  border-radius: 8px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  transition: 0.5s;
  z-index: 1;
}

.service-item:hover .service-content::after {
  height: 100%;
  opacity: 1;
}

.service-item .service-content-icon {
  position: relative;
  z-index: 2;
}

.service-item .service-content-icon i,
.service-item .service-content-icon p {
  transition: 1s;
  font-size: 15px;
  display: grid;
  margin: 2px;
}

.service-item:hover .service-content-icon i {
  color: #ff671f !important;
}

.service-item:hover .service-content-icon p {
  color: #fff;

}

.service-content-icon .imgservice img {
  height: 100px;
  width: 100px;
}

.service-item:hover .service-content-icon h4 {
  color: #ff671f;
}

.service-item:hover .service-content-icon a.btn-primary {
  background: #fff;
  color: #000;
}

.service-item .service-content-icon a.btn-primary {
  transition: 1s !important;
}

.card-text:hover p {
  color: #ccc;


}

.dropbtn {
  background-color: #3498DB;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #2980B9;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}

.service-content-icon .service-img {
  height: 30%;
  // width: 100px;
}

.img-fluid {
  max-width: 100%;
  height: 150px;
}

.cards-wrapper {
  margin-top: 4%;
  margin-bottom: 6%;
  background-color: transparent;
}

.detaliscard {
  margin-top: 6%;

  p {
    justify-content: left;
  }
}

.news-section .card,
.news-details-page .card,
.news-page .card {
  background-color: #f8f9fa;
  width: 100% !important;
  border-radius: 10px;
  // padding-top:10%;
  // margin-top: 10%;
  box-shadow: 0px 8px 25px rgba(211, 211, 211, 0.25);
  height: 450px;

  img {
    height: 100px;
    //border-radius: 10px 10px 0 0;
  }


  .card-details {
    display: flex;
    //justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px;


    h5 {
      font-size: clamp(14px, 1.9vw, 18px);
      font-weight: 500;
      width: 100%;
      // height: 300px;
      text-size-adjust: 10px;
      color: #0033a0;
      overflow: hidden;
      // white-space: nowrap;
      margin-inline-end: 10px;
      max-width: 70%;
      margin-top: 10px;
      padding: 10px;
      float: inline-start;
    }

    .card-description {
      margin-bottom: 20px;
    }

    span {
      color: #AA5656;
      font: normal normal normal 1em/1 'LineIcons';
      margin-top: 20px;
      font-size: 13px;

    }

    .btn {
      color: #212529;
    }

  }

  span,
  p {
    font-size: clamp(12px, 1.5vw, 14px);
  }

  .card-text {
    color: #212529;
    height: 40px;
    overflow: hidden;
    margin-bottom: 10px;
    // @media #{$min-small} {
    //     height: 55px;
    // }
    // @media #{$min-med} {
    //     height: 40px;
    // }

  }

  .card {
    height: 250px;
  }

}

.contact {

  .label {
    color: #4a4a4a;
    border-left: 3px solid #0056b3;
    padding: 1%;
    display: flex;
    text-transform: capitalize;
    background-color: #e6f1ff;
    margin-bottom: 10%;
  }

  .text {
    color: #424242;
    width: 100%;
    max-width: 60vw;
    display: block;
    box-sizing: border-box;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  .phone {
    padding-top: 3%;

    h5 {
      color: #000;
      font-size: 16px;
      text-transform: capitalize;
      padding-right: 5px;

    }

  }

  .focal {
    .ff {
      font-size: 15px;
      float: left;
      // height: 300px;
      width: 24%;
      color: #0033a0;
      flex: 20%;
    }

    .fff {
      float: left;
      width: 4%;

      p {
        color: #0033a0;
      }

    }

    label {
      text-transform: capitalize;
      // color:#0033a0;
    }

    p {
      color: #4a4a4a;
      font-size: 15px;
      // float: left;
      //  padding-right: 20px;
    }
  }
}

.detail {
  // width: 100%;
}

.working {


  .label {
    color: #4a4a4a;
    border-left: 3px solid #0056b3;
    padding: 1%;
    display: flex;
    //width: 100%;
    margin-bottom: 2.5%;
    text-transform: capitalize;
    background-color: #e6f1ff;
    // margin-bottom: 10%;
  }

  .text {
    color: #424242;
    width: 100%;
    max-width: 60vw;
    display: block;
    box-sizing: border-box;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }
}

.point {
  // float: left;
  padding: 1%;
}

.SBb-cont {
  height: -webkit-fill-available;
  position: relative;
  background-color: #0033a0;
  width: 20%;
  margin-inline-start: -31px;

  ul {

    li {

      a {

        width: 20% !important;
        display: inline-block;
        padding: 10px;
        margin-inline-start: -8px;

        &.active {
          background: linear-gradient(to left, blue, #fff 500%, #a09eff 30%);
          //  border-inline-start: 2px solid #c6c5fa;
        }
      }

      a {
        display: inline-block;
        color: #fff;
        font-size: 16px;
      }
    }
  }

}